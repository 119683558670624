import {
  BadgeIcon,
  ButtonIcon,
  ColumnsIcon,
  EmbedIcon,
  HorizontalDivider,
  ImagesGrid,
  LandingPage,
  QuoteIcon,
  SingleImage,
} from '../page-style/admin/editor-console/content/widget/widget.style'

export const widgetsData = [
  {
    section: 'Basic',
    disabled: false,
    collections: [
      { name: 'Text/HTML', type: 'content', Icon: EmbedIcon, disabled: false },
      {
        name: 'Horizontal Divider',
        type: 'horizontal_rule',
        Icon: HorizontalDivider,
        disabled: false,
      },
      { name: 'Column', type: 'column', Icon: ColumnsIcon, disabled: false },
      // { name: 'Header', type: 'header', Icon: HeaderIcon, disabled: false }, // Have no yet id matched to Header widget
      { name: 'Button', type: 'button', Icon: ButtonIcon, disabled: false },
    ],
  },
  {
    section: 'Tenmplates',
    disabled: false,
    collections: [
      // { name: 'Article Hero', type: 'article_hero', Icon: ArticleIcon, disabled: false },
      {
        name: 'Single Image',
        type: 'single_image',
        Icon: SingleImage,
        disabled: false,
      },
      {
        name: 'Image Grid',
        type: 'image_grid',
        Icon: ImagesGrid,
        disabled: false,
      },
      { name: 'Quote', type: 'quote', Icon: QuoteIcon, disabled: false },
      { name: 'CTA', type: 'cta', Icon: BadgeIcon, disabled: false },
      {
        name: 'Supplier Reference',
        type: 'featured_suppliers',
        Icon: LandingPage,
        disabled: false,
      },
    ],
  },
]

export const widgetIndex = {
  content: 1,
  image_grid: 2,
  quote: 3,
  horizontal_rule: 4,
  column: 5,
  featured_suppliers: 6,
  cta: 7,
  html: 8,
  single_image: 9,
  podcast: 10,
  banner: 11,
  button: 12,
} as any
