'use client'

import styled from 'styled-components'
import { Embed2 as Embed } from '@styled-icons/icomoon/Embed2'
import { LineHorizontal1 } from '@styled-icons/fluentui-system-regular/LineHorizontal1'
import { Image } from '@styled-icons/ionicons-solid/Image'
import { Images } from '@styled-icons/ionicons-solid/Images'
import { Quote } from '@styled-icons/foundation/Quote'
import { PersonBadgeFill } from '@styled-icons/bootstrap/PersonBadgeFill'
import { Columns } from '@styled-icons/octicons/Columns'
import { Article } from '@styled-icons/remix-line/Article'
import { DocumentPageBottomCenter } from '@styled-icons/fluentui-system-filled/DocumentPageBottomCenter'
import { RequestPage } from '@styled-icons/material-rounded/RequestPage'
import { TextHeader1 } from '@styled-icons/fluentui-system-filled/TextHeader1'
import { SmartButton } from '@styled-icons/material-rounded/SmartButton'
import { Youtube } from '@styled-icons/boxicons-logos/Youtube'
import { Vimeo } from '@styled-icons/boxicons-logos/Vimeo'
import Button from 'antd/lib/button'
import Upload from 'antd/lib/upload'
import DrawerAnt from 'antd/lib/drawer'
import { Trash as TrashAnt } from '@styled-icons/heroicons-outline/Trash'
import { ChevronDown as ChevronDownAnt } from '@styled-icons/heroicons-outline'
import { DragIndicator } from '@styled-icons/material/DragIndicator'
import { Label } from '../../../../../components/ui/input/input.label.style'
import { PROD_URL } from '@/constants'

// TODO build failed on dynamic import then need to avoid failed and it will remove right after the build app function is improved
const customGrey = '#C8C8C8'

export const ConsoleWidgetStyle = styled.div<{ toggle?: boolean }>`
  ${({ toggle }) => `
    display: block;
    position: fixed;
    overflow-y: auto;
    top: 57px;
    left: 0;
    grid-area: widget;
    max-width: 250px;
    width: 250px;
    height: 100%;
    background-color: ${customGrey};
    transition: left 0.5s ease-in-out;
    overflow-y: auto;
    box-shadow: 17px 0px 19px -18px ${customGrey};
    ${toggle ? 'left: 0px' : 'left: -260px'};
    z-index: 99;
  `}
`

export const Title = styled.div<{ disabled?: boolean }>`
  ${({
    theme: {
      base: {
        shade: { blue },
        grey,
      },
    },
    disabled,
  }) => `
    display: block;
    width: 100%;
    height: 50px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: 50px;
    text-transform: uppercase;
    background-color: ${disabled ? '#484848' : blue[0]};
    color: ${disabled ? grey[10] : grey[17]};
    border-bottom: 1px solid ${blue[3]};
  `}
`

export const Drawer = styled(DrawerAnt)`
  padding: 0 1rem;
`

export const ContainerStyle = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => `
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 1rem;
    width: 100%;
    ${disabled ? 'opacity: 0.5' : 'opacity: unset'};
  `}
`

const extendMargin = 'margin: auto;'

export const EmbedIcon = styled(Embed).attrs({
  size: 50,
})`
  ${extendMargin}
`

export const HorizontalDivider = styled(LineHorizontal1).attrs({
  size: 70,
})`
  ${extendMargin}
`

export const ImagesGrid = styled(Images).attrs({
  size: 40,
})`
  ${extendMargin}
`

export const SingleImage = styled(Image).attrs({
  size: 40,
})`
  ${extendMargin}
`

export const QuoteIcon = styled(Quote).attrs({
  size: 50,
})`
  ${extendMargin}
`

export const BadgeIcon = styled(PersonBadgeFill).attrs({ size: 40 })`
  ${extendMargin}
`
export const ColumnsIcon = styled(Columns).attrs({ size: 40 })`
  ${extendMargin}
`
export const ArticleIcon = styled(Article).attrs({ size: 40 })`
  ${extendMargin}
`
export const LandingPage = styled(DocumentPageBottomCenter).attrs({ size: 40 })`
  ${extendMargin}
`
export const SupplerIcon = styled(RequestPage).attrs({ size: 40 })`
  ${extendMargin}
`
export const HeaderIcon = styled(TextHeader1).attrs({ size: 40 })`
  ${extendMargin}
`
export const ButtonIcon = styled(SmartButton).attrs({ size: 40 })`
  ${extendMargin}
`
export const YoutubeIcon = styled(Youtube).attrs({ size: 40 })`
  ${extendMargin}
`
export const VimeoIcon = styled(Vimeo).attrs({ size: 40 })`
  ${extendMargin}
`
export const ChevronDown = styled(ChevronDownAnt).attrs({ size: 20 })`
  ${extendMargin}
`

export const ButtonContainer = styled.div<{ alignment?: string }>`
  ${({ alignment = 'center' }) => `
    display: flex;
    width: 100%;
    justify-content: ${alignment};
    gap: 10px;
  `}
`

export const DisplaySimulation = styled.div<{ position?: string }>`
  ${({
    theme: {
      base: { grey },
    },
    position = 'center',
  }) => `
    display: flex;
    position: relative;
    gap: 10px;
    min-height: 200px;
    background-color: ${grey[1]};

    align-items: center;
    justify-content: center;

    &::after {
      position: absolute;
      top: 5px;
      left: 5px;
      content: '${position}';
      display: block;
      text-transform: capitalize;
      color: #C0C0C0;
    }
  `}
`

export const ButtonAction = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 5px;
  height: 50px;
  margin: 0 0 2rem;
`

export const LayoutButton = styled.div`
  display: block;
  width: 50%;
`

export const LayoutButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const ActionButtonGroup = styled.div<{ enabled?: boolean }>`
  ${({ enabled }) => `
    display: grid;
    position: relative;
    grid-template-columns: 100%;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    height: ${enabled ? '40px' : '0'};
    transition: height 0.2s ease-in-out;
  `}
`

export const ItemWrapper = styled.div<{ color?: string }>`
  ${({
    color,
    theme: {
      base: {
        shade: { white, green },
      },
    },
  }) => `
    box-sizing: border-box;
    width: 100%;
    position: relative;
    background-color: ${color || white[0]};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 15px;
      transform: scaley(0);
      background-color: ${green[2]};
      transition: all 0.2s ease-in-out;
    }

    &::after {
      content: '+Add';
      display: block;
      font-weight: 700;
      position: absolute;
      top: -2px;
      left: 50%;
      font-size: 10px;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.2s ease-in-out;
    }

    &.heightlight {
      &::before {
        transform: scaley(1);
      }

      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:hover {
      ${ActionButtonGroup} {
        height: 40px;
      }
    }

  `}
`

export const ItemBody = styled.div``

export const WidgetContainer = styled.div`
  display: block;
`

export const ButtonControl = styled(Button)``

export const Trash = styled(TrashAnt).attrs(() => ({
  size: 20,
}))``

export const DragArea = styled.div`
  ${() => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  background-color: #F8F8F8;
`}
`

export const ButtonGroup = styled.div`
  display: block;
  position: absolute;
  right: 20px;
`

export const DragIcon = styled(DragIndicator).attrs({
  size: 20,
})``

export const LabelForm = styled.label`
  margin: 0.6rem 0;
  display: block;
`

export const EditorContent = styled.div`
  display: block;
`

export const EditorGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: space-between;
`

export const Section = styled.section`
  display: block;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  position: relative;
  transition: height 0.5s ease-in-out;

  label {
    font-weight: 700;
    text-transform: capitalize;
  }
`

const buttonStyle = (borderColor: string) => `
  display: flex;
  height: 40px;
  width: 150px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${borderColor};
  cursor: pointer;
  &:hover {
    background-color: ${borderColor};
  }
`

export const UploadWidget = styled(Upload)`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    ${buttonStyle(grey[10])}
  `}
`
export const UploadFeatureWidget = styled(UploadWidget)<{ label?: string }>`
  ${({ label = '' }) => `
    display: flex;
    height: fit-content;
    justify-content: flex-start;;
    align-items: center;
    cursor: pointer;
    border: unset;
    position: relative;
    margin: 1rem 0 0;

    &:hover {
      background-color: unset;
    }

    &::after {
      content: '${label}';
      display: block;
      position: absolute;
      top: -30px;
      left: 0;
    }
  `}
`

export const ImageUploadContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const ImageDescription = styled.div`
  display: block;
  width: 100%;
`

export const SelectWidget = styled.div`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    ${buttonStyle(grey[10])}
  `}
`

export const ButtonUpload = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const ImageWidgetContainer = styled.div`
  display: block;
`

export const ImageButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const ImageContiner = styled.div<{
  $isMultiple?: boolean
  isEmpty?: boolean
}>`
  ${({
    theme: {
      base: { grey },
    },
    $isMultiple,
    isEmpty = false,
  }) => `
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    ${
      $isMultiple
        ? `
    align-items: flex-start;
    align-content: flex-start;
    min-height: 200px;
    padding: 1rem;
    position: relative;
    max-height: 400px;
    overFlow: auto;
    border: 1px solid ${grey[3]};
    box-shadow: inset 0px 8px 20px -16px ${'#484848'};
    `
        : ''
    }

    ${
      isEmpty
        ? `
      &::after {
        content: 'Empty..';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${grey[5]};
      }
    `
        : ''
    }

  `}
`

export const ImageDisplay = styled.div<{
  url: string
  failed?: boolean
  isError?: boolean
}>`
  ${({
    theme: {
      base: {
        grey,
        shade: { red, white },
      },
    },
    failed,
    url,
    isError,
  }) => `
    display: block;
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 0 1rem;
    background-color: ${grey[5]};
    ${
      url
        ? `background-image: url(${url});`
        : `background-image: url(${PROD_URL}/assets/images/placeholder.jpeg);`
    }
    background-size: cover;
    background-position: center;
    border: 2px solid ${isError ? red[0] : grey[10]};
    border-radius: 10px;
    box-shadow: 0 0 7px 0px #C0C0C0;

    ${
      failed
        ? `

      &::after {
        content: 'Upload failed';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 0.7rem;
        transform: translate(-50%, -50%);
        color: ${white[1]};
        background-color: ${red[3]};
        padding: 0 0.5rem;
        width: 100%;
        font-weight: 700;
        text-align: center;
      }

    `
        : ''
    }
  `}
`

export const EditorContainer = styled.div`
  padding: 1rem;
`

export const LibraryAsset = styled.div`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    display: block;
    width: 100%;
    height: 500px;
    background-color: ${grey[4]};
    margin-top: 3rem;
    position: relative;

    &::before {
      position: absolute;
      top: -30px;
      left: 0;
      font-size: 1.1rem;
      content: 'Library assets';
      display: block;
      font-weight: 700;
    }
  `}
`

export const RoundClose = styled(Trash).attrs({
  size: 25,
})`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    background-color: ${grey[1]};
    color: ${'#696969'};
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    box-shadow: 3px 2px 7px 1px #696969;
    padding: 0.4rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:active {
      background-color: #C0C0C0;
      color: ${'#686868'};
    }
  `}
`

export const DeleteSection = styled(RoundClose).attrs({
  size: 25,
})`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
background-color: ${grey[1]};
color: ${'#696969'};
border-radius: 0;
position: absolute;
top: 5px;
right: 5px;
box-shadow: 3px 2px 7px 1px #696969;
padding: 0.4rem;
width: 30px;
height: 30px;
cursor: pointer;
transition: all 0.1s ease-in-out;
z-index: 999;

&:active {
  background-color: #C0C0C0;
  color: ${'#686868'};
}
`}
`

export const ExpandSection = styled(ChevronDownAnt)`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
  background-color: ${grey[1]};
  color: ${'#696969'};
  border-radius: 0;
  position: absolute;
  top: 5px;
  right: 40px;
  box-shadow: 3px 2px 7px 1px #696969;
  padding: 0.4rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 999;

  &:active {
    background-color: #C0C0C0;
    color: ${'#686868'};
  }
`}
`

export const ProgressDivAnim = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: red;
`

export const ReferenceContainer = styled.div`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    max-height: 500px;
    overflow: auto;
    position: relative;
    border: 1px solid ${grey[6]};
    padding: 1rem 0.5rem;
    border-radius: 10px;
    margin: 1rem 0;
  `}
`

export const TitleContainer = styled.div`
  display: block;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 10px;
`

export const CheckContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const SupplierSectionStyle = styled.section`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    display: block;
    border: 1px solid ${grey[7]};
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem 0;
    background-color: ${grey[1]};
  `}
`

export const SupplierActionBarStyle = styled.section`
  ${({
    theme: {
      base: {
        shade: { blue, white },
      },
    },
  }) => `
    display: flex;
    width: 100%;
    padding: 0.5rem;
    background-color: ${blue[0]};
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    ${Label} {
      margin: 0;
      float: left;
      color: ${white[0]};
    }
  `}
`

export const WrappAction = styled.div`
  display: flex;
`

export const CollapeContent = styled.div<{ toggle?: boolean }>`
  ${({ toggle }) => `
  display: block;
  height: ${toggle ? '330px' : '100px'};
  overflow: hidden;
  transition: height 0.4s ease-in-out;
 `}
`

export const LibraryAllAssetContainer = styled.div`
  display: block;
  width: 100%;
  min-height: 300px;
  max-height: 400px;
  overflow: auto;
`

export default ConsoleWidgetStyle
