export function addQueryParamIfAbsent(
  urlString: string,
  paramName: string,
  paramValue: string
): string {
  const url = new URL(urlString)
  const queryParams = url.searchParams

  if (
    !queryParams.has(paramName) ||
    queryParams.get(paramName) !== paramValue
  ) {
    queryParams.set(paramName, paramValue)
  }

  return url.toString()
}
