import { addQueryParamIfAbsent } from '@/utils/addQueryParamIfAbsent'
import NextImage from 'next/image'
import React from 'react'
import s from './image.module.css'
import cx from 'classnames'

export type ImageLoadingType = 'lazy' | 'eager'

type ImageProps = Omit<React.ComponentProps<typeof NextImage>, 'ref'> & {
  pictureStyle?: React.CSSProperties
  src: string | { url: string }
  videoUrl?: string
  width?: number
  height?: number
  fit?: boolean
  noFit?: boolean
  noOptimize?: boolean
  noScaleUp?: boolean
  pictureClassName?: string
  loading?: ImageLoadingType
  className?: string
  fill?: boolean
}

export function addOriginalExtensionToPath(originalUrl: string) {
  const regexPattern = /(\d+x\d+\/)/
  const extensionMatch = originalUrl.match(/\.(jpg|png|jpeg|webp)/)
  if (extensionMatch) {
    return originalUrl.replace(regexPattern, `$1${extensionMatch[1]}/`)
  }

  return originalUrl
}

function prependResolutionToPath(
  originalUrl: string,
  width: number,
  height: number
) {
  const regexPattern = /(\d+x\d+\/)/
  const resolutionMatch = originalUrl.match(regexPattern)
  if (!resolutionMatch) {
    try {
      const url = new URL(originalUrl)
      url.pathname = `${width}x${height}` + url.pathname

      return url.toString()
    } catch (error) {
      return originalUrl
    }
  }

  return originalUrl
}

export function overrideImageDimensionsInUrl(
  url: string,
  overrides: { width?: number; height?: number }
): string {
  const resolutionRegex = /(\d+)x(\d+)/
  const match = url?.match(resolutionRegex)

  if (match) {
    // Extract the width and height from the regex match
    const width = overrides.width || parseInt(match[1], 10)
    const height = overrides.height || parseInt(match[2], 10)

    // Replace the old resolution with the new resolution in the URL
    return url?.replace(resolutionRegex, `${width}x${height}`)
  } else {
    return url
  }
}

function containsDomain(input?: string): boolean {
  const domains = [
    'rockmywedding.co.uk',
    'rmw.rn-staging.com',
    'https://ddfvdysqksylx.cloudfront.net',
  ]
  return domains.some((domain) => input?.includes(domain))
}

function isSvgIcon(urlString: string): boolean {
  const svgPattern = /\.svg/i
  return svgPattern.test(urlString)
}

export const Image = ({
  src: _src,
  alt,
  pictureStyle,
  width,
  height,
  fit,
  noFit,
  noOptimize,
  noScaleUp,
  pictureClassName,
  loading = 'lazy',
  fill,
  ...restProps
}: ImageProps) => {
  const srcString = typeof _src === 'string' ? _src : _src?.url
  const isImageHostedByUs = containsDomain(srcString)

  const { srcSetJpg, srcSetWebp, src } = (() => {
    if (!srcString) {
      return {
        srcSetWebp: undefined,
        srcSetJpg: undefined,
        src: undefined,
      }
    }

    let src = srcString

    if (fit) {
      src = addQueryParamIfAbsent(src, 'fit', '1')
    }

    if (noFit) {
      src = src.replace(/&?fit=1/, '')
    }

    /**
     * prepend resolution to path only if width and height are provided
     */
    if (width && height) {
      src = prependResolutionToPath(src, width, height)
    }

    const srcWithModifiedPath = addOriginalExtensionToPath(src)
    const webpSrc = srcWithModifiedPath?.replace(
      /\.(png|jpg|jpeg|webp)/,
      '.webp'
    )

    const scaleUpFactor = noScaleUp ? 1 : 2

    const webpSrc1 = overrideImageDimensionsInUrl(webpSrc, { width, height })
    const webpSrc2 = overrideImageDimensionsInUrl(webpSrc, {
      width: width! * scaleUpFactor,
      height: height! * scaleUpFactor,
    })
    src = overrideImageDimensionsInUrl(src, { width, height })
    const src2 = overrideImageDimensionsInUrl(src, {
      width: width! * scaleUpFactor,
      height: height! * scaleUpFactor,
    })

    return {
      srcSetWebp: `${webpSrc1} 1x, ${webpSrc2} 2x`,
      srcSetJpg: `${src} 1x, ${src2} 2x`,
      src,
    }
  })()

  if (isSvgIcon(srcString) || !isImageHostedByUs || noOptimize) {
    return (
      <>
        <NextImage
          width={width}
          height={height}
          alt={alt || ''}
          src={srcString}
          loading={loading}
          fill={fill}
          unoptimized
          {...restProps}
        />
      </>
    )
  }

  return (
    <>
      <picture style={pictureStyle} className={pictureClassName}>
        <source srcSet={srcSetWebp} type="image/webp" />
        <source srcSet={srcSetJpg} type="image/jpeg" />
        <img
          alt={alt}
          src={src}
          loading={loading}
          decoding={loading === 'lazy' ? 'async' : 'sync'}
          fetchPriority={loading === 'eager' ? 'high' : 'low'}
          {...restProps}
          className={cx(
            {
              [s.fill]: fill,
            },
            restProps.className
          )}
        />
      </picture>
    </>
  )
}
